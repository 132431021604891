export const AppRoute = {
    HomePage:"/",
    AboutUs:"/aboutUs",
    Services:"/services",
    Pricing:"/pricing",
    Contact:"/contact",
    Analysis:"/analysis",
    Testing:"/testing",
    Page404:"/page404"

}